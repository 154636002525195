
import { ObserveVisibility } from 'vue-observe-visibility';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters/ListingFilter';
import { Listing } from 'client-website-ts-library/types/Listing';
import { API } from 'client-website-ts-library/services';
import { WebsiteLevel } from 'client-website-ts-library/types';

import ListingCard from './ListingCard.vue';
import Loader from './UI/Loader.vue';

@Component({
  components: {
    ListingCard,
    Loader,
  },
  directives: {
    'observe-visibility': ObserveVisibility,
  },
})
export default class Listings extends Vue {
  @Prop()
  private readonly filter!: ListingFilter;

  @Prop({ default: true })
  private readonly infiniteScroll!: boolean;

  @Prop({ default: 'No listings found.' })
  private readonly noListingsText!: string;

  private loading = false;

  private errored = false;

  private listings: Listing[] = [];

  private more = true;

  load(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.loading || !this.more) resolve(false);

      this.loading = true;

      API.Listings.Search(this.filter).then((data) => {
        this.more = data.Pages > this.filter.Page;

        this.listings.push(...data.Items);

        resolve();

        this.$emit('got_listing_count', data.Count);
      }).catch(reject);
    });
  }

  loadMore(): void {
    if (this.loading) return;

    this.filter.Page += 1;

    this.load().then(() => {
      this.loading = false;
    });
  }

  update(): void {
    this.listings = [];
    this.more = true;
    this.loading = false;

    this.load().then(() => {
      this.loading = false;
    });
  }

  mounted() {
    this.update();
  }

  private filterUpdateDebounce: number | undefined = undefined;

  @Watch('filter.Suburbs')
  @Watch('filter.SearchId')
  handleFilterParamsUpdated(): void {
    this.loading = true;
    this.listings = [];

    clearTimeout(this.filterUpdateDebounce);

    this.filterUpdateDebounce = window.setTimeout(() => {
      this.loading = false;

      this.update();
    }, 1000);
  }
}
